import React from 'react';
import './App.css';
import weddingGroup from './imgs/wedding_group.jpg';
import embrace from './imgs/embrace.jpg';
import cheers from './imgs/cheers.jpg';

const App: React.FC = () => {
    return (
        <div className="App">
            <section>
                <div className="container">
                    <img style={{width: '100%'}} src={weddingGroup} alt="wedding group"/>
                    <div className="centered">Thank you for coming!</div>
                </div>
            </section>
            <section>
                <div>
                    <img style={{width: '100%'}} src={embrace} alt="embrace"/>
                </div>
            </section>
            <section>
                <div>
                    <img style={{width: '100%'}} src={cheers} alt="cheers"/>
                </div>
            </section>
        </div>
    );
};

export default App;
